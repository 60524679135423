
import request from '@/plugins/request'

//商品管理-商品列表 列表
export function goodsList(params = {}){
  if(params.itemStatus) params.itemStatus = Number(params.itemStatus)
  if(params.approval) params.approval = Number(params.approval )
  return request({
    url: `/api/goods/item/getItemList`,
    method: 'POST',
    data: params,
  })
}

// 商品管理-商品列表 详情
export function goodsById(id){
  let params = {
    itemId: id,
  }
  return request({
    url: '/api/goods/item/getItem',
    method: 'GET',
    params: params,
  })
}


// 产品编号下拉（分页）
export function getProductList(params = {}){
  return request({
    url: '/api/goods/product/getProductList',
    method: 'POST',
    data: params,
  })
}
//获取商品类目下拉(树形)
export function getTree(){
  return request({
    url: '/api/goods/category/getCategoryTree',
    method: 'GET',
  })
}

// 商品管理-商品新增
export function goodsAdd(data){
  if(data.isAfterSales) data.isAfterSales = Number(data.isAfterSales)
  if(data.isPresale) data.isPresale = Number(data.isPresale)
  if(data.isQuantity) data.isQuantity = Number(data.isQuantity)
  // data.presaleDate = Number(data.presaleDate)
  data.presaleDate = new Date(data.presaleDate).getTime()
  // data.deliverDate = new Date(data.deliverDate).getTime()
  return request({
    url: '/api/goods/item/addItem',
    method: 'POST',
    data,
  })
}
// 商品管理-商品修改
export function goodsUpdate(data){
  if(data.isAfterSales) data.isAfterSales = Number(data.isAfterSales)
  if(data.isPresale) data.isPresale = Number(data.isPresale)
  if(data.isQuantity) data.isQuantity = Number(data.isQuantity)
  data.presaleDate = new Date(data.presaleDate).getTime()
  return request({
    url: '/api/goods/item/updateItem',
    method: 'POST',
    data,
  })
}

// 商品管理-商品详情
export function getGoods(params = {}){
  return request({
    url: '/api/goods/item/getItem',
    method: 'GET',
    params,
  })
}

// 商品管理-商品发布
export function postItem(itemId){
  return request({
    url: '/api/goods/item/putItem'+'?itemId='+itemId,
    method: 'GET',
    params: {},
  })
}
// 商品管理-商品下架



export default{
  //商品管理-添加商品

  //商品管理-商品列表
  goodsList,
  goodsById,

  getProductList,
  getTree,
  goodsAdd,
  getGoods,
  postItem,
}