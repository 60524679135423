<template>
  <div>
    <gl-title :title="$route.meta.title"></gl-title>
    <div style="margin: 18px;">
      <!-- <el-button type="primary" size="mini" @click="$router.push({path: 'update'})"> 新增 </el-button> -->
      <el-button type="primary" size="mini" @click="$router.push({path: 'add'})"> 新增 </el-button>
    </div>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="itemId" label="商品ID">
          <gl-list-search v-model.trim="mFormData.itemId" placeholder="商品ID" @enter="mGetList"></gl-list-search>
        </el-form-item>
        <el-form-item prop="itemName" label="商品名称">
          <gl-list-search v-model.trim="mFormData.itemName" placeholder="商品名称" @enter="mGetList"></gl-list-search>
        </el-form-item>
        <el-form-item prop="itemName" label="上下架">
          <gl-option v-model="mFormData.itemStatus" :list="statusList"></gl-option>
        </el-form-item>
        <el-form-item prop="approval" label="审批状态">
          <gl-option v-model="mFormData.approval" :list="approvalList"></gl-option>
        </el-form-item>
        <el-form-item prop="startTime" label="上架时间">
          <gl-date-picker 
            valueFormat='yyyy-MM-dd'
            :ref="mDateRef"
            :startTime.sync="mFormData.listtingTimeStart" 
            :endTime.sync="mFormData.listtingTimeEnd">
          </gl-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="itemId" label="产品ID" width="150"></el-table-column>
        <el-table-column prop="itemName" label="产品名称"></el-table-column>
        <el-table-column prop="pictureUrl" label="商品主图">
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.pictureUrl"
              fit="cover"
              :preview-src-list="[scope.row.pictureUrl]"
            />
          </template>
        </el-table-column>
        <el-table-column prop="listtingTime" label="上架时间"></el-table-column>
        <el-table-column prop="itemStatus" label="上下架">
          <template slot-scope="{row}">
            <span v-if="row.itemStatus == 1"> 上架 </span>
            <span v-if="row.itemStatus == 2"> 下架 </span>
            <span v-if="row.itemStatus == 3"> 售罄 </span>
            <span v-if="row.itemStatus == 4"> 删除 </span>
          </template>
        </el-table-column>
        <el-table-column prop="approval" label="审批状态">
          <template slot-scope="{row}">
            <span v-if="row.approval == 1"> 审批通过 </span>
            <span v-if="row.approval == 2"> 审批不通过 </span>
            <span v-if="row.approval == 3"> 待审批 </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="130">
          <template slot-scope="{row}">
            <el-button v-if="row.itemStatus !== 1" type="text" @click="$router.push({path: 'add', query:{itemId: row.itemId, title: '修改'}})"> 修改 </el-button>
            <el-button v-if="row.itemStatus == 2 && row.approval !== 3" type="text" @click="mPublish(row.itemId)"> 上架 </el-button>
            <el-button v-if="row.itemStatus == 1 && row.approval !== 3" type="text" @click="mRevoke(row.itemId)"> 下架 </el-button>
            <el-button type="text" @click="$router.push({path: 'detail', query:{itemId: row.itemId}})"> 详情 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import {goodsList,postItem} from '@/api/merchant/goods'

export default {
  name: 'goodsList',
  mixins: [ListMixin],
  data(){
    return {
      statusList: [
        {name: '上架', value: '1'},
        {name: '下架', value: '2'},
        {name: '售罄', value: '3'},
        {name: '删除', value: '4'},
      ],
      approvalList: [
        {name: '审批通过', value: '1'},
        {name: '审批不通过', value: '2'},
        {name: '待审批', value: '3'}
      ]
    }
  },
  mounted(){
    this.mPublishFun = postItem
    this.mRevokeFun = postItem
    this.mGetListFun = goodsList
    this.mGetList()
  },
  methods: {
    
  },
}
</script>

<style lang="scss">

</style>